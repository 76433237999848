.widget-enter {
    opacity: 0;
}
.widget-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}
.widget-exit {
    opacity: 1;
}
.widget-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}