.dropdown-enter {
  opacity: 0;
}
.dropdown-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}
.dropdown-exit {
  opacity: 1;
}
.dropdown-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in;
}