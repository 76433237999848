@font-face {
  font-family: 'Futura';
  font-weight: bold;
  src: url(../public/fonts/FuturaCom-Bold.ttf);
}

@font-face {
  font-family: 'Futura';
  font-weight: 300;
  src: url(../public/fonts/FTR35.otf) format('opentype');
}

@font-face {
  font-family: 'Futura';
  font-weight: 300;
  font-style: italic;
  src: url(../public/fonts/FTR36.otf) format('opentype');
}

@font-face {
  font-family: 'Futura';
  font-weight: 400;
  src: url(../public/fonts/FTR45.otf) format('opentype');
}

@font-face {
  font-family: 'Futura';
  font-weight: 400;
  font-style: italic;
  src: url(../public/fonts/FTR46.otf) format('opentype');
}

@font-face {
  font-family: 'Futura';
  font-weight: 500;
  src: url(../public/fonts/FTR55.otf) format('opentype');
}

@font-face {
  font-family: 'Futura';
  font-weight: 500;
  font-style: italic;
  src: url(../public/fonts/FTR56.otf) format('opentype');
}

@font-face {
  font-family: 'Futura';
  font-weight: 600;
  src: url(../public/fonts/FTR65.otf) format('opentype');
}

@font-face {
  font-family: 'Futura';
  font-weight: 600;
  font-style: italic;
  src: url(../public/fonts/FTR66.otf) format('opentype');
}