@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.dropdown-menu, .dropdown-menu-mobile {
  color: white;
  padding-right: 15px;
  align-self: center;
}

.dropdown-menu, .tag-remove, .dropdown-menu-mobile .tag-remove {
  margin-right: 20px;
  color: white;
  height: 60%;
  cursor: pointer;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  outline: none;
  transition: 200ms ease-in-out;
  text-transform: capitalize;
}

.dropdown-menu .tag-remove:hover, .dropdown-menu-mobile .tag-remove:hover {
  background: rgba(246, 132, 122, 0.73);
}

.dropdown-menu .dropdown-content {
  background-color: rgba(0, 0, 0, 0.73);
  position: absolute;
  border-radius: 0px 0px 4px 4px;
  top: 100%;
  min-width: 230px;
  right: 0;
  max-width: fit-content;
}


.dropdown-menu-mobile .dropdown-content {
  background-color: rgba(0, 0, 0, 0.73);
  position: absolute;
  border-radius: 0px 0px 4px 4px;
  top: 100%;
  min-width: 150px;
  right: 0;
  max-width: fit-content;
}

.dropdown-not-search .search {
  display: none;
}

.dropdown-search .search {
  background-color: transparent;
  color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  width: 100%;
  transform: translateY(85%);
  outline: none;
}

.dropdown-menu .tag, .dropdown-menu-mobile .tag {
  display: none;
}

.dropdown-menu .tag-list, .dropdown-menu-mobile .tag-list {
  display: flex;
}

.dropdown-menu .tag-item {
  list-style-type: none;
  width: max-content;
}

.dropdown-menu-mobile .tag-item {
  list-style-type: none;
  width: max-content;
}

.dropdown-menu .tag-item > .placeholder {
  cursor: pointer;
  transition: color 200ms ease-in-out;
  text-transform: uppercase;
  font-size: 18px;
  font-family: Futura;
  font-weight: 300;

  text-decoration: underline;
  text-decoration-color: black;

  position: absolute;
  top: 31%;
  right: 140px;
}

.dropdown-menu-mobile .tag-item > .placeholder {
  cursor: pointer;
  transition: color 200ms ease-in-out;
  text-transform: uppercase;
  font-size: 10px;
  text-align: center;
  font-family: Futura;
  font-weight: 300;

  position: absolute;
  bottom: 3%;
  right: 100px;
}

.dropdown-menu .tag-item > .placeholder::after {
  font: 21px/1 "Material Icons";
  content: " \E5CC";
  
  position: absolute;
  top: 5%
}

.dropdown-menu-mobile .tag-item > .placeholder::before {
  font: 32px/1 "Material Icons";
  content: "  \e152 \A";
  white-space: pre-line;
  position: absolute;
  bottom: 120%;
  left: -12%
}

.dropdown-menu-mobile .node-label {
  line-height: 40px;
}

.dropdown-menu .tag-item > .placeholder:hover, .dropdown-menu-mobile .tag-item > .placeholder:hover  {
  text-decoration: underline;
  text-decoration-color: white;
  filter: brightness(110%);
}

.dropdown-menu .dropdown-trigger, .dropdown-menu-mobile .dropdown-trigger {
  border-radius: 0.25rem;
}

.dropdown-menu .dropdown-trigger > span::after, .dropdown-menu-mobile .dropdown-trigger > span::after {
  font-size: 12px;
}

.dropdown-menu .toggle, .dropdown-menu-mobile .toggle {
  font: normal normal normal 24px/1 "Material Icons";
  color: white;
  white-space: pre;
  margin-right: 4px;
  vertical-align: middle;
}

.dropdown-menu .toggle:hover, .dropdown-menu-mobile .toggle:hover {
  cursor: pointer;
}

.dropdown-menu .toggle.collapsed::after, .dropdown-menu-mobile .toggle.collapsed::after {
  content: "\E5CC";
}

.dropdown-menu .toggle.expanded::after, .dropdown-menu-mobile .toggle.expanded::after {
  content: "\E5Cf";
}

.dropdown-menu .checkbox-item, .dropdown-menu-mobile .checkbox-item {
  position: relative;
  width: .75rem;
  height: .75rem;
  margin-right: 0.75rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: middle;
}

.dropdown-menu .checkbox-item::before, .dropdown-menu-mobile .checkbox-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 1px solid #aaa;
  transition: all 0.2s ease-in-out;
}

.dropdown-menu .checkbox-item:checked::before, .dropdown-menu-mobile .checkbox-item:checked::before {
  height: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top-style: none;
  border-right-style: none;
  border-color: #3A8643;
}

.dropdown-menu .root, .dropdown-menu-mobile .root {
  padding: 10px;
  margin: 10px;
}